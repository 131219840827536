import * as React from "react"
import Colors from "../../constants/Colors"
import Variables from "../../constants/Variables"

interface Props {
  text: string
}

export default function SmallHeadline({ text }: Props) {
  return (
    <span
      className="uppercase"
      style={{
        color: Colors.lightThemeColor,
        fontSize: 12,
        fontFamily: Variables.fontFamilyHeadline,
      }}
    >
      {text}
    </span>
  )
}
