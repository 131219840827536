import { Link } from 'gatsby';
import React from 'react'
import { Edge } from '../../models/NationalPark';
import Container from '../layout/Container';
import WideContainer from '../layout/WideContainer';
import TailwindCard from '../cards/TailwindCard';

interface Props {
    parks: Edge[];
}

export default function PopularParks({ parks }: Props) {
  return (
    <div className="px-4 pt-4">
        <div className="pt-6 pb-12">
            <Container center={true} className="py-4">
                <div className="text-center">
                    <h2>Visit some of the popular national parks</h2>
                    <p>
                        Some parks have earned a great reputation across the globe. 
                        Here is a small subset of some of the most popular parks among visitors.
                    </p>
                </div>
            </Container>
            <WideContainer center={true}>
                <div className="grid grid-cols-1 md:grid-cols-3 py-4">
                    <div className="mr-2">
                        <TailwindCard 
                            park={parks[0].node.data}
                            parkLink={parks[0].node.uid}
                            tags={parks[0].node.tags}
                        />
                    </div>
                    <div className="px-2">
                        <TailwindCard
                            park={parks[1].node.data}
                            parkLink={parks[1].node.uid}
                            tags={parks[1].node.tags}
                        />
                    </div>
                    <div className="ml-2">
                        <TailwindCard 
                            park={parks[2].node.data}
                            parkLink={parks[2].node.uid}
                            tags={parks[2].node.tags}
                        />
                    </div>
                </div>
            </WideContainer>
        </div>  
    </div>
  );
}