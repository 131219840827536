import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
  center?: boolean,
  padding?: boolean
}

const SmallContainer: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
  center,
  padding
}) => (
  <div className={`container ${center ? 'mx-auto' : ''} ${padding ? 'px-4' : ''} ${className}`}>
    <div className={`lg:w-6/12 ${center ? 'mx-auto' : ''}`}>{children}</div>
  </div>
);

SmallContainer.defaultProps = {
  className: ``,
  center: false,
  padding: true
} as Partial<ComponentProps>;

export default SmallContainer;
