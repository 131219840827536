import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout/Layout"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import ImageTextComponent from "../components/landingpage/ImageTextComponent"
import PopularParks from "../components/nationalpark/PopularParks"
import { Edge } from "../models/NationalPark"
import Header from "../components/landingpage/Header"
import WhyAlper from "../components/landingpage/WhyAlper"
import MapboxComponent from "../components/maps/MapboxComponent"
import ExploreCategories from "../components/nationalpark/ExploreCategories"
import LayoutWithoutNavbar from "../components/layout/LayoutWithoutNavbar"

interface Props extends PageProps {
  data: {
    allPrismicNationalPark: {
      edges: Edge[]
    }
  }
  location: any
}

const Home: React.FunctionComponent<Props> = ({ data, location }: Props) => {
  return (
    <LayoutWithoutNavbar>
      <MetaData
        title="National Parks - Hiking, Sights and Information"
        description="Alper gives you access to information about national parks. Get the best tips to experience the wild nature on our planet."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <Header />
      <PopularParks parks={data.allPrismicNationalPark.edges} />
      <ImageTextComponent />
      <WhyAlper />
      <ExploreCategories
        title="Find a park that fit your interest"
        subtitle="Get a better overview of the park characteristics and find one to suit you."
      />
    </LayoutWithoutNavbar>
  )
}

export const parkQuery: any = graphql`
  {
    allPrismicNationalPark(filter: { tags: { eq: "Popular" } }) {
      edges {
        node {
          id
          uid
          tags
          data {
            national_park_name {
              text
              type
            }
            key_text
            park_size
            park_founded_year
            continent
            country
            region {
              text
              type
            }
            description {
              text
              type
            }
            national_park_coordinates {
              latitude
              longitude
            }
            national_park_border {
              border_coordinate {
                latitude
                longitude
              }
            }
            official_website {
              link_type
              url
            }
            hiking_available
            climbing_available
            rafting_available
            horseback_riding_available
            snorkeling_available
            mountain_biking_available
            fishing_available
            bird_watching_available
            valuable_links {
              link_href {
                url
                link_type
              }
              link_name {
                text
                type
              }
            }
            key_text
            region {
              type
              text
            }
            getting_there {
              text
              type
            }
            when_to_visit {
              text
              type
            }
            season_to_visit
            bear_danger
            main_park_image {
              Large {
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
              Medium {
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
              Thumbnail {
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
              alt
              copyright
              dimensions {
                height
                width
              }
              url
            }
            image_gallery {
              gallery_image {
                url
                copyright
                alt
                Extra_Large {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Medium {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Thumbnail {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                dimensions {
                  height
                  width
                }
                small {
                  alt
                  copyright
                  dimensions {
                    width
                    height
                  }
                  url
                }
              }
            }
            sights {
              sight_name {
                text
                type
              }
              sight_address {
                text
                type
              }
              sight_description {
                text
                type
              }
              sight_price {
                text
                type
              }
              sight_location {
                latitude
                longitude
              }
              sight_type
              wikipedia_text
              sight_image {
                Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Medium {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Thumbnail {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
            }
            visitor_centers {
              center_name {
                text
                type
              }
              center_address {
                text
                type
              }
              center_coordinates {
                latitude
                longitude
              }
              center_description {
                text
                type
              }
              center_image {
                Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Medium {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Thumbnail {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
            }
            activities {
              activity_address {
                text
                type
              }
              activity_description {
                text
                type
              }
              activity_image {
                Extra_Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Large {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Medium {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Thumbnail {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              activity_location {
                latitude
                longitude
              }
              activity_price {
                text
                type
              }
              activity_suitable_for
              activity_title {
                text
                type
              }
              activity_type
              wikipedia_text
            }
            animals {
              related_animals {
                id
                isBroken
                lang
                link_type
                slug
                type
              }
            }
            hikes {
              hike_description {
                text
                type
              }
              hike_difficulty
              wikipedia_text
              hike_time
              hike_starting_location {
                latitude
                longitude
              }
              hike_name {
                text
                type
              }
              hike_length
              hike_image {
                Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Medium {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Thumbnail {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
            }
            shelters {
              shelter_description {
                text
                type
              }
              shelter_food_available
              shelter_image {
                alt
                copyright
                dimensions {
                  height
                  width
                }
                square_large {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                square_medium {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                square_small {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                thumbnail {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                url
              }
            }
            nature_spots {
              nature_spot_coordinates {
                latitude
                longitude
              }
              nature_spot_description {
                text
                type
              }
              nature_spot_image {
                Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Medium {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Small {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Thumbnail {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              nature_spot_title {
                text
                type
              }
              nature_spot_type
              wikipedia_text
            }
            mountains {
              mountain_coordinates {
                latitude
                longitude
              }
              mountain_title {
                text
                type
              }
              mountain_description {
                text
                type
              }
              mountain_elevation
              wikipedia_text
              mountain_image {
                Extra_Large {
                  alt
                  copyright
                  url
                  dimensions {
                    height
                    width
                  }
                }
                Large {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Medium {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                Thumbnail {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  url
                }
                alt
                copyright
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Home
