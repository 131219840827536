import { RecipeModel } from "../models/RecipeModel"

const sanitizeUrl = (string: string) =>
  string
    .toLowerCase()
    .replace("æ", "ae")
    .replace("ø", "oe")
    .replace("å", "a")
    .replace("å", "a")
    .replace("á", "a")
    .replace("á", "a")
    .replace("ă", "a")
    .replace("ă", "a")
    .replace("â", "a")
    .replace("â", "a")
    .replace("ā", "a")
    .replace("ā", "a")
    .replace("ó", "o")
    .replace("č", "c")
    .replace("ç", "c")
    .replace("ć", "c")
    .replace("ć", "c")
    .replace("đ", "d")
    .replace("đ", "d")
    .replace("ë", "e")
    .replace("é", "e")
    .replace("ê", "e")
    .replace("ê", "e")
    .replace("ķ", "k")
    .replace("ķ", "k")
    .replace("&", "and")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace("'", "")
    .replace("'", "")
    .replace("(", "")
    .replace(")", "")
    .replace(":", "")
    .replace(":", "")
    .replace("ö", "o")
    .replace("ö", "o")
    .replace("ő", "o")
    .replace("ô", "o")
    .replace("ô", "o")
    .replace("ó", "o")
    .replace("ó", "o")
    .replace("ð", "o")
    .replace("ð", "o")
    .replace("Þ", "p")
    .replace("Þ", "p")
    .replace("ß", "ss")
    .replace("š", "s")
    .replace("ä", "a")
    .replace("ä", "a")
    .replace("ä", "a")
    .replace("ń", "n")
    .replace("ń", "n")
    .replace("ñ", "n")
    .replace("ñ", "n")
    .replace("ś", "s")
    .replace("ş", "s")
    .replace("ş", "s")
    .replace("š", "s")
    .replace("š", "s")
    .replace("ę", "e")
    .replace("ž", "z")
    .replace("í", "i")
    .replace("í", "i")
    .replace("ī", "i")
    .replace("ī", "i")
    .replace("ü", "u")
    .replace("ü", "u")
    .replace("ū", "u")
    .replace("ū", "u")
    .replace("ú", "u")
    .replace("ú", "u")
    .replace("ř", "r")
    .replace("ř", "r")
    .replace("ț", "t")
    .replace("ț", "t")
    .replace("ť", "t")
    .replace("ť", "t")
    .replace("ý", "y")
    .replace("ý", "y")
    .replace("  ", "-")

const getInstagramUser = (instagramLink: string): string => {
  return (
    "@" +
    instagramLink.slice(
      instagramLink.indexOf("com/") + 4,
      instagramLink.lastIndexOf("/")
    )
  )
}

const objectIsMissingValue = (
  obj: RecipeModel,
  keys: Array<string>
): Boolean => {
  for (var i = 0; i < keys.length; i++) {
    if (obj.hasOwnProperty(keys[i]) !== true) {
      return true
    }
  }
  return false
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

export const Helpers = {
  sanitizeUrl,
  getInstagramUser,
  objectIsMissingValue,
  hasOwnProperty,
}
