import { Link } from "gatsby"
import React from "react"
import Container from "../layout/Container"
import WideContainer from "../layout/WideContainer"
import { StaticImage } from "gatsby-plugin-image"
import Routes from "../../constants/Routes"

interface Props {
  title: string
  subtitle: string
}

export default function ExploreCategories({ title, subtitle }: Props) {
  return (
    <div className="px-4 pt-4">
      <div className="pt-6 pb-12">
        <Container center={true} className="py-0">
          <div className="text-center">
            <h2 className="mb-0">{title}</h2>
            <p className="mb-0 mt-1">{subtitle}</p>
          </div>
        </Container>
        <WideContainer center={false}>
          <div className="grid grid-cols-1 md:grid-cols-3 py-4">
            <div className="md:mr-2">
              <Link to={Routes.TAG_ALPINE} title={`Alpine National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/sidebar/mountain-sidebar.jpg"
                      alt="Alpine National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Alpine parks</h3>
                      <span className="absolute text-sm text-light ml-4 mt-14">
                        Explore the highest peaks
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="md:px-2">
              <Link to={Routes.TAG_CLIFFS} title={`Cliff National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/sidebar/cliff-sidebar.jpg"
                      alt="Cliff National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">
                        Parks with cliffs
                      </h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore rugged landscapes
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="md:ml-2">
              <Link to={Routes.TAG_FOREST} title={`Forest National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/explore-forest.jpg"
                      alt="Forest National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Forest parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore plant ecosystems
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </WideContainer>
      </div>
    </div>
  )
}
