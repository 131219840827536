import { Link } from "gatsby"
import * as React from "react"

interface Props {
  text: string
  link: string
  className?: string
}

export default function SimpleInternalButton({
  text,
  link,
  className = "",
}: Props) {
  return (
    <Link to={link}>
      <button
        className={`bg-medium text-md text-black py-2 px-10 rounded inline-flex items-center font-semibold ${className}`}
        style={{ fontFamily: "Euclid" }}
      >
        <span>{text}</span>
      </button>
    </Link>
  )
}
