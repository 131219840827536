import { Link } from "gatsby"
import React from "react"
import Colors from "../../constants/Colors"
import Variables from "../../constants/Variables"
import { Helpers } from "../../services/Helpers"
import { NationalPark } from "../../models/NationalPark"
import { IoEarth } from "react-icons/io5"
import { AiFillInfoCircle } from "react-icons/ai"

interface Props {
  park: NationalPark
  parkLink: string
  tags: string[]
}

export default function TailwindCard({ park, parkLink, tags }: Props) {
  const link = `/${parkLink}/`
  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6">
      <Link to={link} title={`${parkLink}`}>
        <div
          style={{
            background: `url(${park.main_park_image.url})`,
          }}
          className="park-image"
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-36">
        <Link to={link} title={`${parkLink}`}>
          <h4 style={{ marginTop: 5, marginBottom: 5 }}>
            {park.national_park_name[0].text}
          </h4>
        </Link>
        <div className="mt-0 mb-2">
          <Link
            to={`/continent/${Helpers.sanitizeUrl(park.continent)}/`}
            title={`Continent: ${park.continent}`}
          >
            <span style={styles} className="mr-2">
              {park.continent}
            </span>
          </Link>
          <span style={character} className="mr-2">
            •
          </span>
          <Link
            to={`/country/${Helpers.sanitizeUrl(park.country)}/`}
            title={`Country: ${park.country}`}
          >
            <span style={styles} className="mr-2">
              {park.country}
            </span>
          </Link>
          <span style={character} className="mr-2">
            •
          </span>
          <Link
            to={`/region/${Helpers.sanitizeUrl(park.region[0].text)}/`}
            title={`Region: ${park.region[0].text}`}
          >
            <span style={styles} className="mr-2">
              {park.region[0].text}
            </span>
          </Link>
        </div>
        <div>
          <IoEarth
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span
            className="inline-block ml-1 text-sm"
            style={{ color: Colors.almostBlack }}
          >
            {park.park_size} km²
          </span>
          <span style={character} className="mr-2 ml-2">
            •
          </span>
          <AiFillInfoCircle
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span
            className="inline-block ml-1 text-sm"
            style={{ color: Colors.almostBlack }}
          >
            {park.park_founded_year}
          </span>
        </div>
      </div>
      <div className="px-6 pt-4 pb-4">
        {tags.map((tag, index) => {
          return (
            <Link
              key={index}
              to={"/tag/" + Helpers.sanitizeUrl(tag) + "/"}
              title={tag}
            >
              <div
                className="inline-block text-xs mr-2 py-1 px-2 bg-lightest rounded-lg hover:bg-light focus:outline-none"
                style={{ color: Colors.almostBlack }}
              >
                {tag}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 14,
} as React.CSSProperties

const character = {
  color: Colors.themeColor,
  fontSize: 12,
  fontFamily: Variables.fontFamilyHeadline,
} as React.CSSProperties
