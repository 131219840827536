import * as React from 'react';
import Container from '../layout/Container';

export default function WhyAlper() {
  return (
    <Container className="mt-16" center>
        <h2 className="mb-4">Why should you use Alper?</h2>
        <hr className="w-48 mb-4 border-2" />
        <p className="text-gray-900 mt-1 mb-3">
            Alper is meant to provide information for anyone interested in experiencing nature.
            it's not just for the experienced people who have been visiting the parks for years.
            With the right information, anyone can do it.
        </p>
        <h5>Research at your fingertips</h5>
        <p className="text-gray-900 mt-1 mb-3">
            Information regarding national parks have long been dispersed on several websites or apps for individual parks.
            Alper provides a central starting point for researching all national parks outside of the US.
            You can use the website or the app to find information. This can be done from the comfort of your home or on the road towards the park.
        </p>
        <h5>The best preparation</h5>
        <p className="text-gray-900 mt-1 mb-3">
            Alper does not just give you specific sights and acitvities to do in the park. 
            It also prepares you with guides on how to properly prepare for your visit.
            This includes checklists, precations and much more.
        </p>
    </Container>
  );
}