import * as React from "react"
import { FaCheck } from "react-icons/fa"
import Colors from "../../constants/Colors"
import Variables from "../../constants/Variables"

export default function CheckmarkComponent() {
  return (
    <div className="pt-3">
      <div className="inline">
        <FaCheck
          size={16}
          className="inline bg-lightgreen rounded-full w-6 h-6 p-1 mb-1"
          color={Colors.themeColor}
        />
        <span
          className="inline pl-2 text-md text-gray-100"
          style={{
            color: Colors.lightThemeColor,
            fontFamily: Variables.fontFamilyHeadline,
          }}
        >
          Completely free
        </span>
      </div>
      <div className="pl-4 inline">
        <FaCheck
          size={16}
          className="inline bg-lightgreen rounded-full w-6 h-6 p-1 mb-1"
          color={Colors.themeColor}
        />
        <span
          className="inline pl-2 text-md text-gray-100"
          style={{
            color: Colors.lightThemeColor,
            fontFamily: Variables.fontFamilyHeadline,
          }}
        >
          Easy to use
        </span>
      </div>
    </div>
  )
}
