import * as React from "react"
import SmallHeadline from "../typography/SmallHeadline"
import CheckmarkComponent from "./CheckmarkComponent"
import SimpleInternalButton from "../buttons/SimpleInternalButton"
import Routes from "../../constants/Routes"
import FrontPageNavBar from "../navbar/FrontPageNavbar"
import SmallContainer from "../layout/SmallContainer"

export default function Header() {
  return (
    <div>
      <div className="mb-4">
        <div
          className=""
          style={{
            backgroundImage:
              "url(" +
              "https://images.prismic.io/nationalparks/275a8a93-a5a5-47b9-b811-64198ef0410f_header-background.jpg?auto=compress,format" +
              ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <FrontPageNavBar />
          <SmallContainer center={true} className="pt-24 pb-64 content-center">
            <SmallHeadline text="Enjoy the outdoors" />
            <h1 className="mt-2 text-white">Explore National Parks</h1>
            <p className="text-base 2xl:text-lg text-light">
              National Parks are some of the greatest places to experience the
              true wonders of nature. Start your journey here and get inspired
              for your travels.
            </p>
            <CheckmarkComponent />
            <br></br>
            <SimpleInternalButton
              text="View countries"
              link={Routes.COUNTRIES}
              className="mt-1 mb-1"
            />
          </SmallContainer>
        </div>
      </div>
    </div>
  )
}
