import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Colors from '../../constants/Colors';

export default function ImageTextComponent() {
    return (
        <div className="mt-4 mb-4 md:mb-16 theme-border-bottom theme-border-top">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="md:pr-2">
                    <StaticImage 
                        src="../../images/alps.jpg" 
                        alt="The European Alps in Switzerland"
                        placeholder="blurred"
                    />
                </div>
                <div style={{ backgroundColor: Colors.green }} className="md:ml-2 md:p-32 p-8 vertical-center">
                    <div>
                        <h2 style={{ color: Colors.white }} className="mb-4">Experience true nature</h2>
                        <p style={{ color: Colors.lightGrey }} className="mt-1 mb-3">
                            The national parks are some of the last bastions of true nature.
                            They range from smaller areas to areas the size of countries.
                            You will be able to find most imaginable nature experiences in these parks.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}